import { createRouter, createWebHistory, } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';

import PrivacyPage from '@/views/PrivacyPage.vue';
import HomePage from '@/views/HomePage.vue';
import VCNotisPage from '@/views/VCNotisTestPage.vue';
import VCNotisPageLogin from '@/views/VCNotisTestPagelLogin.vue';
import DocsPage from '@/views/DocsPage.vue';
import ToolsPage from '@/views/ToolsPage.vue'
import LoginPage from '@/views/LoginPage.vue';
import RegisterPage from '@/views/RegisterPage.vue';
import ForgotPage from '@/views/ForgotPage.vue';
import VerifyPage from '@/views/VerifyPage.vue';
import ContactsPage from '@/views/newContactsPage.vue';
import ProfilePage from '@/views/ProfilePage.vue';
import NotificationPage from '@/views/NotificationsPage.vue';
import ViewcodePage from '@/views/ViewcodePage.vue';
import SessionsPage from '@/views/SessionsPage.vue';
import AboutPage from '@/views/AboutPage.vue';
import NotFound from '@/views/404Page.vue';

const routes: Array<RouteRecordRaw> = [
//  
//  {path: '/', component: LoginPage},
  {path: '/', component: HomePage},
  {path: '/home', component: HomePage},
  {path: '/alert', component: VCNotisPage},
  {path: '/alert/:sid', component: VCNotisPage},
  {path: '/alerts', component: VCNotisPageLogin},
  {path: '/alerts/:sid', component: VCNotisPageLogin},
  {path: '/viewcode', component: ViewcodePage},
  {path: '/viewcode/:viewcode', component: VCNotisPageLogin},
  {path: '/docs', component: DocsPage},
  {path: '/unsubscribe', component: ToolsPage},
  {path: '/privacy', component: PrivacyPage},
  {path: '/deletemyprofile', component: ToolsPage},
  {path: '/dns', component: ToolsPage},
  {path: '/login', component: LoginPage},
  {path: '/register', component: RegisterPage},
  {path: '/forgot', component: ForgotPage},
  {path: '/verify', component: VerifyPage},
//  {path: '/profile', component: ProfilePage},
  {path: '/datarequest', component:ToolsPage},
  {path: '/tools',  meta: {requiresAuth: true},component:ToolsPage},
  {path: '/about', component: AboutPage},
  {path: '/contacts', meta: {requiresAuth: true}, component: ContactsPage},
  {path: '/profile', meta: {requiresAuth: true}, component: ProfilePage},
  {path: '/sessions', meta: {requiresAuth: true}, component: SessionsPage},
  {path: '/notifications', meta: {requiresAuth: true}, component: NotificationPage},

  {path: "/:notFound", component: HomePage},
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
