
import { initializeApp } from 'firebase/app'; //commented out for easy
import { getAuth, setPersistence, browserLocalPersistence } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getFunctions } from "firebase/functions";

import { alert } from 'ionicons/icons';

const firebaseConfig = {
    apiKey: "AIzaSyD5rX2CQJgGZ0lG9lcp5kc2osoUJocUcdA",
    authDomain: "sm-dev-7f6f1.firebaseapp.com",
    projectId: "sm-dev-7f6f1",
    storageBucket: "sm-dev-7f6f1.appspot.com",
    messagingSenderId: "399392621708",
    appId: "1:399392621708:web:b4d92d8ab61d073a5c67a9",
    measurementId: "G-V122P1SJ4H",
    
  };

// init firebase
const app = initializeApp(firebaseConfig);
const functions = getFunctions(app, "europe-west3");
import {getStorage} from 'firebase/storage';
const storage = getStorage(app);

//init FCM


//const messaging = getMessaging();

//onMessage(messaging, (payload) => {
//    console.log('Message received. ', payload);
//    // ...
//  });

//getToken(messaging, {vapidKey: "BJinpEfj3nFpVTzdcjuNAHPGPJ3Nlzl6GJxXsqrB0Ki3wE3vXWBGoCPOmWevVaIfrkRRADtjEPJAq3LUxRvcyLE"})
//.then((currentToken) => {
//    if (currentToken) {
//        console.log('Messaging token is: ' + currentToken);
//      // Send the token to your server and update the UI if necessary
//      // ...
//    } else {
//      // Show permission request UI
//      console.log('No registration token available. Request permission to generate one.');
//      // ...
//    }
//  }).catch((err) => {
//    console.log('An error occurred while retrieving token. ', err);
//    // ...
//  });

export {functions}
export {storage}
// init firebase auth
const auth = getAuth()
export { auth }
setPersistence(auth, browserLocalPersistence);
const db = getFirestore()
export default db;