import { createI18n } from "vue-i18n"
import { DocumentData, collection, doc, setDoc, deleteDoc, getDoc, query } from "firebase/firestore";
import { getAuth } from "firebase/auth";
//import {pluralRules} from "./rules/pluralization"
//import numberFormats from "./rules/numbers.ts"
//import datetimeFormats from "./rules/datetime.js"
import af from "./locales/af.json"
import am from "./locales/am.json"
import ar from "./locales/ar.json"
import be from "./locales/be.json"
import bg from "./locales/bg.json"
import ca from "./locales/ca.json"
import zhCN from "./locales/zh-CN.json"
import zhTW from "./locales/zh-TW.json"
import hr from "./locales/hr.json"
import cs from "./locales/cs.json"
import da from "./locales/da.json"
import nl from "./locales/nl.json"
import en  from "./locales/en.json"
import et from "./locales/et.json"
import tl from "./locales/tl.json"
import fi from "./locales/fi.json"
import fr from "./locales/fr.json"
import de from "./locales/de.json"
import el from "./locales/el.json"
import he from "./locales/he.json"
import hi from "./locales/hi.json"
import hu from "./locales/hu.json"
import id from "./locales/id.json"
import it from "./locales/it.json"
import ja from "./locales/ja.json"
import ko from "./locales/ko.json"
import lv from "./locales/lv.json"
import lt from "./locales/lt.json"
import ms from "./locales/ms.json"
import no from "./locales/no.json"
import fa from "./locales/fa.json"
import pl from "./locales/pl.json"
import pt from "./locales/pt.json"
import ro from "./locales/ro.json"
import ru from "./locales/ru.json"
import sr from "./locales/sr.json"
import sk from "./locales/sk.json"
import sl from "./locales/sl.json"
import es from "./locales/es.json"
import sw from "./locales/sw.json"
import sv from "./locales/sv.json"
import th from "./locales/th.json"
import tr from "./locales/tr.json"
import uk from "./locales/uk.json"
import vi from "./locales/vi.json"
import zu from "./locales/zu.json"

import db from '../firebaseConfig'
const auth = getAuth();


export default createI18n({
  locale: 'en',
fallbackLocale: 'en',
  legacy: false,
  globalInjection: true,
  messages: { zu, vi, uk, tr, th, sv, sw, es, sl, sk, sr, ru, ro, pt, pl, fa, no, ms, lt, lv, ko, ja, it, id, hu, hi, he, el, de, fr, fi, tl, et, en, nl, da, cs, hr, ca, bg, be, ar, am, af, zhCN, zhTW },
  runtimeOnly: false,
  
//  pluralRules,
//  numberFormats,
//  datetimeFormats
})
